.App {
  font-family: "Times New Roman", Times, serif;
}
#container {
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  width: 80%;
  margin: 10%;
}

#header {
  width: 50%;
  text-align: center;
  border-radius: 0px;
  background-color: #ffffff;
  border-style: none;
  border-bottom: 1px solid #ddd;
  display: inline-block;
  color: #666;
  font-weight: normal;
  line-height: 3;
  padding: 0 0 45px 0;
}
#header:hover {
  color: rgb(0, 0, 0);
  box-shadow: none;
}
.active {
  color: #4d90fe;
  border-bottom: 2px solid #4d90fe;
}

#fn {
  font-size: xx-large;
  font-size: 62px;
  margin: 20px 16px 20px 16px;
  padding: 16px 12px;
}
sub {
  font-size: 30px;
}
.msec {
  margin-left: 5px;
  font-size: 45px;
}

button {
  min-width: 75px;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 3px;
  font-weight: bold;
  display: inline-block;
  height: 31px;
  line-height: 1px;
  margin-top: 3px;
  padding: 0 9px;
  text-align: center;
}
button:focus {
  outline: none;
}
button:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.start {
  background-color: #4d90fe;
  border: 1px solid #3079ed;
  color: #fff;
}

.start:active {
  border-color: #4d90fe;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4);
}
.start:focus {
  border-color: #323233;
}
.start:hover {
  border-color: #2f5bb7;
  background-color: #357ae8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4d90fe),
    to(#2c74e7)
  );
}

.reset {
  margin-left: 15px;
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  color: #555;
}
.reset:focus {
  border-color: #4d90fe;
}
.reset:active {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.reset:hover {
  border-color: #c6c6c6;
  color: #333;
  background-color: #f8f8f8;
}
#buttons {
  border-block-start-width: 50%;
  border-top: 1px solid #ddd;
  padding: 10px 0 11px 10px;
}
